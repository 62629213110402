<template>
    <div style="position:relative" v-bind:class="{'show':openSuggestion}">
        <input class="form-control" :class="inputClass" type="text" :value="value" :placeholder="filterPlaceholderText" @input="updateValue($event.target.value)"
            @keydown.enter = 'enter'
            @keydown.down = 'down'
            @keydown.up = 'up'>
        <ul v-if="matches.length > 0" class="dropdown-menu suggestions" style="width:100%" v-bind:class="{'show':openSuggestion}">
            <li v-for="(suggestion, index) in matches"
                v-bind:class="{'active': isActive(index)}"
                @click="suggestionClick(index)"
                :key="suggestion.id">
              <a href="#">{{ suggestion.name }} <small>{{ suggestion.state }}</small>
              </a>
            </li>
        </ul>
    </div>
</template>

<style>
    .active { background-color: #D3D3D3; }
    ul.suggestions {
        width: 100%;
        max-height: 500px;
        overflow: scroll;
    }
</style>

<script>
const _ = require('lodash');

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    suggestions: {
      type: Array,
      required: true
    },
    filterPlaceholderText: {
        type: String,
        required: false
    },
    inputClass: {
        type: String,
        required: false
    },
    supportIdSearches: {
        type: Boolean,
        default: true,
        required: false
    },
  },
  data () {
    return {
    current: 0,
    matches: [],
    openSuggestion: false,
    valueClickOccurred: false
    }
  },
  created () {
      let chartFilters = this.localDb.getChartFilters();
      if (chartFilters.selectedAccountsToFilter && chartFilters.selectedAccountsToFilter.length > 0) {
          this.$emit('input', chartFilters.selectedAccountsToFilter[0].name);
      }
  },
  watch: {
    value: function(val) {
        if (this.current > this.matches.length-1) this.current = 0;
        
        if (this.valueClickOccurred) {
            this.valueClickOccurred = false;
            return;
        }
        
        let found = null;
        if (this.supportIdSearches && val && val.charAt(0) == '#') {
            let idLookup = val.slice(1);
            found = _.filter(this.suggestions, (suggestion) => { return suggestion.id.includes(idLookup) });
        } else {
            found = _.filter(this.suggestions, (suggestion) => { return suggestion.name.toLowerCase().includes(val.toLowerCase()) });
        }
        this.matches = found;
        if (found) this.openSuggestion = true;
        if (!val) {
            this.openSuggestion = false;
            this.current = 0;
        }
    }
  },
  methods: {
    updateValue (value) {
    //   if (this.open === false) {
    //     this.open = true
    //     this.current = 0
    //   }    
      this.$emit('input', value)
    },
    // For highlighting element
    isActive (index) {
      return index === this.current
    },
    // When one of the suggestion is clicked
    suggestionClick (index) {
      this.valueClickOccurred = true;
      this.openSuggestion = false;
      this.$emit('input', this.matches[index].name);
      this.$emit('update-selection', this.matches[index]);
      //this.open = false;
    },
    // When enter pressed on the input
    enter () {
      this.$emit('input', this.matches[this.current].name);
      this.$emit('update-selection', this.matches[this.current]);
      //this.open = false;
      this.valueClickOccurred = true;
      this.openSuggestion = false;
    },
    // When up pressed while suggestions are open
    up () {
      if (this.current > 0) {
        this.current--
      }
    },
    // When up pressed while suggestions are open
    down () {
      if (this.current < this.matches.length - 1) {
        this.current++
      }
    },
  }
}
</script>