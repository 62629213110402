<template>
  <div class="next-steps">
    <chart-filters @reload-charts="reloadCharts" />
    <br/>
    <chart ref="signupChart" chartName="signups" />
    <chart ref="referralsChart" chartName="referrals" />
  </div>
</template>

<script>
import Chart from "./Chart";
import ChartFilters from "./ChartFilters";

export default {
    name: "HomeContent",
    components: { Chart, ChartFilters },
    data() {
        let today = new Date();
        return {
            filterAccounts: false,
            selection: ''
      };
    },
    mounted: function(){
        this.$refs.signupChart.getSignupEvents();
        this.$refs.referralsChart.getReferralEvents();
    },
    methods: {
        async reloadCharts() {
            await this.$refs.signupChart.getSignupEvents();
            await this.$refs.referralsChart.getReferralEvents();
        }
    }
};
</script>
