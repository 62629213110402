<template>
  <div class="next-steps">
    <chart-filters @reload-charts="reloadCharts" chartName="activePatients" />
    <br/>
    <chart ref="activePatientsChart" chartName="activePatients" />
  </div>
</template>

<script>
import Chart from "../components/Chart";
import ChartFilters from "../components/ChartFilters";

export default {
    name: "Patients",
    components: { ChartFilters, Chart },
    mounted: function(){
        this.$refs.activePatientsChart.getActivePatients();
    },
    methods: {
        async reloadCharts() {
            await this.$refs.activePatientsChart.getActivePatients();
        }
    }
};
</script>
