<template>
  <div class="text-center hero">
    <img class="mb-3 app-logo" src="https://cdn.reviewwave.com/images/logo_login.png" alt="Review Wave" width="120" />
    <h1 class="mb-4">Welcome to the Review Wave Portal.</h1>
    <p class="lead">
      Please <a href="#" class @click="login">Log In</a> to access the portal.
    </p>
  </div>
</template>

<script>
export default {
    name: "Hero",
    methods: {
        login() {
            this.$auth.loginWithRedirect();
        },
    }
};
</script>
