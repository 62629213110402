<template>
    <div>
        <div class="form-row align-items-center">
            <div v-if="!filterAccounts" class="col-auto form-inline">
                <label>You are currently viewing all accounts. &nbsp;</label>
                <button type="button" class="btn btn-primary" @click="filterAccounts = true">Filter by Account</button>
            </div>
            <div v-else class="col-auto form-inline">
                <auto-complete :suggestions="acctLookups" v-model="selection" @update-selection="accountFilterChanged" filter-placeholder-text="Filter by account" input-class="filterAccountInput"  /> &nbsp;
                <button type="button" class="close input-group-text" aria-label="Close" @click="filterAccounts = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="form-row align-items-center">
                <div class="form-check form-switch" v-if="chartName != 'activePatients'">
                    &nbsp;&nbsp;
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="showConverted">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Show only converted patients</label>
                </div>
                <div class="form-check form-switch" v-else>
                    <div class="col-auto form-inline">
                        | &nbsp;&nbsp;&nbsp;&nbsp; Viewing &nbsp;
                        <select id="patientOptionSelect" v-model="selectedPatientOption" class="form-control" @change="reloadCharts">
                            <option v-for="option in patientOptions"  v-bind:value="option.value" :key="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                        <div v-if="!filterAccounts && chartName === 'activePatients'">
                            &nbsp; patients in &nbsp;
                            <select id="categoryOptionSelect" v-model="selectedCategoryOption" class="form-control" @change="reloadCharts">
                                <option v-for="option in categoryOptions"  v-bind:value="option.value" :key="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                            &nbsp; categories
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <div class="form-row align-items-center">
            <div class="col-auto form-inline">
                <div class="form-inline" v-if="chartName != 'activePatients'">
                    <label for="intervalFrequencySelect">Show signup aggregates every: &nbsp;</label>
                    <select id="intervalFrequencySelect" v-model="selectedIntervalFrequency" class="form-control" @change="reloadCharts">
                        <option v-for="option in intervalFrequencyOptions"  v-bind:value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                    &nbsp;
                    <select id="intervalOptionSelect" v-model="selectedInterval" class="form-control" @change="reloadCharts">
                        <option v-for="option in intervalOptions"  v-bind:value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="form-inline" v-else>
                    <label for="intervalPatientSelect">Show patient snapshots of: &nbsp;</label>
                    <select id="intervalPatientSelect" v-model="selectedPatientInterval" class="form-control" @change="reloadCharts">
                        <option v-for="option in patientIntervalOptions"  v-bind:value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                &nbsp; for the following date range &nbsp;
                <date-range-picker 
                    v-model="pickerDates" 
                    :ranges="dateRanges"
                    :auto-apply="true"
                    @update="reloadChartsWithNewDates">
                    <template
                        v-slot:input="picker"
                        style="min-width: 350px;"
                    >{{ pickerDates.startDate | date }} - {{ pickerDates.endDate | date }}</template>
                </date-range-picker>
            </div>
        </div>
    </div>
</template>

<style>
    .filterAccountInput { width: 400px !important; }
</style>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import AutoComplete from "./AutoComplete";
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
const moment = require('moment');
const _ = require('lodash');

export default {
    name: 'ChartFilters',
    components: { DateRangePicker, AutoComplete },
    props: [
        'chartName'
    ],
    created () {
        let chartFilters = this.localDb.getChartFilters();
        if (chartFilters && chartFilters.showOnlyConverted) this.showConverted = true;
        this.selectedInterval = chartFilters.selectedIntervalOption;
        this.selectedIntervalFrequency = chartFilters.selectedIntervalFrequency;
        this.selectedAccountsToFilter = chartFilters.selectedAccountsToFilter;
        let selDateRanges = this.dateRanges[chartFilters.selectedDateRange];
        this.pickerDates = { startDate: selDateRanges[0], endDate: selDateRanges[1] }; //chartFilters.pickerDates;
        this.filterAccounts = chartFilters.selectedAccountsToFilter.length > 0;
        this.selectedPatientOption = chartFilters.selectedPatientOption;
        this.selectedPatientInterval = chartFilters.selectedPatientInterval;

        //this.categoryOptions = this.localDb.getCategories();
        this.categoryOptions = [];
        let catOptions = this.localDb.getCategories();
        _.forEach(catOptions, (option) => {
            this.categoryOptions.push({ text: option, value: option });
        });
        this.selectedCategoryOption = chartFilters.selectedCategoryOption;
    },
    data () {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
        
        let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        return {
            selection: '',
            acctLookups: [],
            selectedAccountsToFilter: [],
            showConverted: false,
            dateRanges: {
                'Last 7 days': [new Date(today - 7 * 24 * 60 * 60 * 1000), today],
                'Last 30 days': [new Date(today - 30 * 24 * 60 * 60 * 1000), today],
                'Last 12 months': [new Date(today - 365 * 24 * 60 * 60 * 1000), today],
                'This month': [thisMonthStart, thisMonthEnd],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
                'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
                'Last year': [new Date(today.getFullYear()-1, 0, 1), new Date(today.getFullYear()-1, 11, 31)],
            },
            intervalOptions: [
                { text: "Day", value: "d" },
                { text: "Week", value: "w" },
                { text: "Month", value: "m" }
            ],
            intervalFrequencyOptions: [
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: "4", value: "4" },
                { text: "5", value: "5" },
                { text: "6", value: "6" },
                { text: "7", value: "7" }
            ],
            patientOptions: [
                { text: 'Active', value: 'active' },
                { text: 'Visits', value: 'visits' }
            ],
            patientIntervalOptions: [
                { text: '1 month', value: '1mo' },
                { text: '6 months', value: '6mo' },
                { text: '12 months', value: '12mo' }
            ],
            selectedInterval: '',
            selectedIntervalFrequency: 0,
            selectedPatientOption: '',
            selectedPatientInterval: '',
            filterAccounts: false,
            categoryOptions: [],
            selectedCategoryOption: 'All'
        }
    },
    mounted: function() {
        this.getRwAccounts();
    },
    watch: {
        selectedInterval: function (val) {
            let chartFilters = this.localDb.getChartFilters();
            chartFilters.selectedIntervalOption = val;
            this.localDb.setChartFilters(chartFilters);
        },
        selectedIntervalFrequency: function (val) {
            let chartFilters = this.localDb.getChartFilters();
            chartFilters.selectedIntervalFrequency = val;
            this.localDb.setChartFilters(chartFilters);
        },
        selectedPatientOption: function (val) {
            let chartFilters = this.localDb.getChartFilters();
            chartFilters.selectedPatientOption = val;
            this.localDb.setChartFilters(chartFilters);
        },
        selectedPatientInterval: function (val) {
            let chartFilters = this.localDb.getChartFilters();
            chartFilters.selectedPatientInterval = val;
            this.localDb.setChartFilters(chartFilters);
        },
        selectedCategoryOption: function (val) {
            let chartFilters = this.localDb.getChartFilters();
            chartFilters.selectedCategoryOption = val;
            this.localDb.setChartFilters(chartFilters);
        },
        showConverted: function(val) {
            let chartFilters = this.localDb.getChartFilters();
            chartFilters.showOnlyConverted = val;
            this.localDb.setChartFilters(chartFilters);
            this.reloadCharts();
        },
        pickerDates: function(val) {
            console.log(`val: ${JSON.stringify(val)}`);
        },
        filterAccounts: function(val) {
            if (!val) {
                this.selection = '';
                if (this.localDb.getChartFilters()) {
                    let chartFilters = this.localDb.getChartFilters();
                    chartFilters.selectedAccountsToFilter = [];
                    this.localDb.setChartFilters(chartFilters);
                }
                this.reloadCharts();
            }
        }
    },
    filters: { date(date) { return new Intl.DateTimeFormat("en-US").format(date); } },
    methods: {
        accountFilterChanged(item) {
            if (item && item.id) {
                if (item.constructor === Array) this.selectedAccountsToFilter = item;
                else {
                    let found = _.find(this.selectedAccountsToFilter, { id: item.id });
                    if (!found) {
                        this.selectedAccountsToFilter = [];
                        this.selectedAccountsToFilter.push(item);
                    }
                }
                let chartFilters = this.localDb.getChartFilters();
                chartFilters.selectedAccountsToFilter = this.selectedAccountsToFilter;
                this.localDb.setChartFilters(chartFilters);
            }
            this.reloadCharts();
        },
        async getRwAccounts() {         
            let accts = await this.localDb.getRwAccounts(this.$auth, this.$http);

            for (const acct of accts.accounts) {
                this.acctLookups.push({ id: acct.id, name: acct.business_name, ownerId: acct.owner_id });
            }
        },
        reloadCharts() { setTimeout(() => { this.$emit('reload-charts') }, 0); },
        reloadChartsWithNewDates(val) {
            let chartFilters = this.localDb.getChartFilters();
            for (const prop in this.dateRanges) {
                if (moment(val.startDate).format('YYYY-MM-DD') == moment(this.dateRanges[prop][0]).format('YYYY-MM-DD') 
                    && moment(val.endDate).format('YYYY-MM-DD') == moment(this.dateRanges[prop][1]).format('YYYY-MM-DD')) {
                    console.log(`${prop} was selected`);
                    chartFilters.selectedDateRange = prop;
                }
            }
            chartFilters.pickerDates = this.pickerDates;
            
            this.localDb.setChartFilters(chartFilters);
            this.reloadCharts();
        }
    }
}
</script>
