<template>
  <div v-if="!$auth.loading">
    <hero v-if="!$auth.isAuthenticated"/>
    <!-- <hr /> -->
    <home-content v-if="$auth.isAuthenticated" />
  </div>
</template>

<script>
import Hero from "../components/Hero";
import HomeContent from "../components/HomeContent";

export default {
  name: "home",
  components: {
    Hero,
    HomeContent
  },
};
</script>

<style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}
</style>
