import Vue from "vue";
import App from "./App.vue";
import Axios from "./plugins/axios";
import router from "./router";
import { Auth0Plugin } from "./auth";
import hljs from "highlight.js/lib/core";
import "highlight.js/styles/github.css";
import VueApexCharts from 'vue-apexcharts';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import AppStyles from './assets/app-theme.css'; // eslint-disable-line no-unused-vars

import localDb from './services/localDb.js'
Object.defineProperty(Vue.prototype, 'localDb', { value: localDb });

const envConfig = require("../env_config.json");

const domain = envConfig.authConfig.domain;
const clientId = envConfig.authConfig.clientId;
const audience = envConfig.authConfig.audience;

Vue.config.productionTip = false;

Vue.use(hljs.vuePlugin);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(Axios);

library.add(faLink, faUser, faPowerOff);

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
