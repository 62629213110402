<template>
    <apexchart width="100%" height="400" type="area" :options="options" :series="series" ref="apexChart"></apexchart>
</template>

<script>
const moment = require('moment');
const _ = require('lodash');
export default {
    props: [
        'chartName'
    ],
    data () {
        return {
            series: [
                
            ],
            options: {},
        }
    },
    created: function() {
        this.options = { 
            chart: {
                type: 'area',
                // height: 350,
                // width: '100%',
                stacked: true,
                events: {
                    selection: function (chart, e) {        
                        console.log(new Date(e.xaxis.min));
                    }
                },
            },
            colors: ['#008FFB', '#00E396', '#CED4DC', '#ffc107', '#ADD8E6', '#e63c6f'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            xaxis: {
                type: 'datetime'
            }
        }

        if (this.chartName == 'signups') this.getSignupEvents();
        else if (this.chartName == 'referrals') this.getReferralEvents();
        else if (this.chartName == 'activePatients') this.getActivePatients();
    },
  methods: {
    async getReferralEvents() {
        const accessToken = await this.$auth.getTokenSilently();
        let apiParams = await this.getApiParams();

        try {
            let chartFilters = this.localDb.getChartFilters();
            //let arrStr = 'account_id[]=130&account_id[]=131&account_id[]=19';
            
            let accountsFilter = this.getAccountsToFilter();
            const { data } = await this.$http.get(`${this.localDb.defaultApiBaseUrl}/report/referrals?${accountsFilter}start_date=${apiParams.sDate}&end_date=${apiParams.eDate}&interval=${apiParams.interval}&showOnlyConverted=${chartFilters.showOnlyConverted}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            //let viewGraphData = [];
            let smsGraphData = [];
            let emailGraphData = [];
            let facebookGraphData = [];
            //let referredGraphData = [];
            _.forEach(data.referrals, (item) => {
                if (item.date) {
                    //viewGraphData.push([new Date(item.date).getTime(), item.counts.view]);
                    smsGraphData.push([new Date(item.date).getTime(), item.counts.sms]);
                    emailGraphData.push([new Date(item.date).getTime(), item.counts.email]);
                    facebookGraphData.push([new Date(item.date).getTime(), item.counts.facebook]);
                    //referredGraphData.push([new Date(item.date).getTime(), item.counts.referred]);
                }
            });

            this.series = [];
            //this.series.push({ name: 'View Referrals', data: viewGraphData });
            this.series.push({ name: 'SMS Referrals', data: smsGraphData });
            this.series.push({ name: 'Email Referrals', data: emailGraphData });
            this.series.push({ name: 'Facebook Referrals', data: facebookGraphData });
            //this.series.push({ name: 'Referred Referrals', data: referredGraphData });
        } catch (e) {
            console.log(`Error: the server responded with '${e.response.status}: ${e.response.statusText}'`);
        }
    },
    async getSignupEvents() {
        const accessToken = await this.$auth.getTokenSilently();
        let apiParams = await this.getApiParams();

        try {
            let chartFilters = this.localDb.getChartFilters();
            //let arrStr = 'account_id[]=130&account_id[]=131&account_id[]=19';
            let accountsFilter = this.getAccountsToFilter();
            const { data } = await this.$http.get(`${this.localDb.defaultApiBaseUrl}/report/signups?${accountsFilter}start_date=${apiParams.sDate}&end_date=${apiParams.eDate}&interval=${apiParams.interval}&showOnlyConverted=${chartFilters.showOnlyConverted}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            let webchatGraphData = [];
            let scheduleGraphData = [];
            let answerGraphData = [];
            let smsGraphData = [];
            let referralGraphData = [];
            let aiGraphData = [];
            _.forEach(data.signups, (item) => {
                if (item.date) {
                    webchatGraphData.push([new Date(item.date).getTime(), item.counts.webchat]);
                    answerGraphData.push([new Date(item.date).getTime(), item.counts.answer]);
                    scheduleGraphData.push([new Date(item.date).getTime(), item.counts.schedule]);
                    smsGraphData.push([new Date(item.date).getTime(), item.counts.sms]);
                    referralGraphData.push([new Date(item.date).getTime(), item.counts.referral]);
                    aiGraphData.push([new Date(item.date).getTime(), item.counts.ai]);
                }
            });

            this.series = [];
            this.series.push({ name: 'Webchat Signups', data: webchatGraphData });
            this.series.push({ name: 'Online Scheduling Signups', data: scheduleGraphData });
            this.series.push({ name: 'Answer Service Signups', data: answerGraphData });
            this.series.push({ name: 'SMS Signups', data: smsGraphData });
            this.series.push({ name: 'Referral Signups', data: referralGraphData });
            this.series.push({ name: 'AI Signups', data: aiGraphData });
        } catch (e) {
            console.log(`Error: the server responded with '${e.response.status}: ${e.response.statusText}'`);
        }
    },
    async getActivePatients() {
        this.buildCustomOptions();
        const accessToken = await this.$auth.getTokenSilently();
        let apiParams = await this.getApiParams();
        let chartFilters = this.localDb.getChartFilters();
        let accountsFilter = this.getAccountsToFilter();

        if (accountsFilter) chartFilters.selectedCategoryOption = 'All';

        const { data } = await this.$http.get(`${this.localDb.defaultApiBaseUrl}/patients/active?${accountsFilter}start_date=${apiParams.sDate}&end_date=${apiParams.eDate}&interval=1d&category=${chartFilters.selectedCategoryOption}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

        let graphData = [];
        _.forEach(data.patients, (item) => {
            if (item.date) {
                let intervalValue = item.stats.perActive12mo;                
                if (chartFilters.selectedPatientInterval == '1mo') intervalValue = (chartFilters.selectedPatientOption == 'active') ? item.stats.perActive1mo : item.stats.perVisits1mo;
                else if (chartFilters.selectedPatientInterval == '6mo') intervalValue = (chartFilters.selectedPatientOption == 'active') ? item.stats.perActive6mo : item.stats.perVisits6mo;
                else intervalValue = (chartFilters.selectedPatientOption == 'active') ? item.stats.perActive12mo : item.stats.perVisits12mo;
                graphData.push({ x: new Date(item.date).getTime(), y: intervalValue, ttlPatients: item.stats.ttlPatients });
            }
        });

        this.series = [];

        let displayName = (chartFilters.selectedPatientOption == 'active') ? 'Active Patients' : 'Patient Visits';
        this.series.push({ name: displayName, data: graphData });
    },
    async getApiParams() {
        let chartFilters = this.localDb.getChartFilters();
        let sDate = moment(chartFilters.pickerDates.startDate).format('YYYY-MM-DD HH:mm:ss');
        let eDate = moment(chartFilters.pickerDates.endDate).format('YYYY-MM-DD 23:59:59');
        let sDateMoment = moment(sDate).utc();
        let eDateMoment = moment(eDate).utc();
        
        let daysDiff = eDateMoment.diff(sDateMoment, 'days');

        if (daysDiff < 28 && chartFilters.selectedIntervalOption == 'm') {
            chartFilters.selectedIntervalOption = 'd';
        } else if (daysDiff > 182 && chartFilters.selectedIntervalOption == 'd') {
            chartFilters.selectedIntervalOption = 'm';
        }

        let interval = `${chartFilters.selectedIntervalFrequency}${chartFilters.selectedIntervalOption}`;

        sDate = sDateMoment.format('YYYY-MM-DD HH:mm:ss');
        eDate = eDateMoment.format('YYYY-MM-DD HH:mm:ss');

        return { sDate, eDate, interval };
    },
    getAccountsToFilter() {
        let accountsFilter = '';
        let chartFilters = this.localDb.getChartFilters();

        if (chartFilters.selectedAccountsToFilter && chartFilters.selectedAccountsToFilter.length > 0) {
            _.forEach(chartFilters.selectedAccountsToFilter, (acct) => {
                accountsFilter += `account_id[]=${acct.id}`;
            })
            if (accountsFilter) accountsFilter += '&';
        }
        return accountsFilter;
    },
    buildCustomOptions() {
        this.options = this.getChartOptions();
    },
    getChartOptions() {
        let chartFilters = this.localDb.getChartFilters();
        return {
            chart: {
                type: 'area',
                // height: 350,
                // width: '100%',
                stacked: true,
                events: {
                    selection: function (chart, e) {        
                        console.log(new Date(e.xaxis.min));
                    }
                },
            },
            colors: ['#008FFB', '#00E396', '#CED4DC', '#ffc107', '#ADD8E6'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            xaxis: {
                type: 'datetime'
            },
            tooltip: {
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    let additionalData = '';                    
                    let formattedTtlPats = parseInt(data.ttlPatients).toLocaleString("en-US");
                    if (data.ttlPatients) additionalData = `<span class="apexcharts-tooltip-text-y-label">Total Patients: </span><span class="apexcharts-tooltip-text-y-value">${formattedTtlPats}</span>`;

                    return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${moment(new Date(data.x)).utc().format('DD MMM')}</div>
                    <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group">
                        <span class="apexcharts-tooltip-text-y-label">${w.globals.initialSeries[seriesIndex].name}: </span><span class="apexcharts-tooltip-text-y-value">${data.y} ${chartFilters.selectedPatientOption == 'active' ? "%" : ''}</span><br/>
                        ${additionalData}
                    </div>`;
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return chartFilters.selectedPatientOption == 'active' ? value + "%" : value.toLocaleString("en-US");
                    }
                },
            }
        };
    },
  }
}
</script>