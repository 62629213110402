export default {
    defaultApiBaseUrl: "https://metrics.reviewwave.net/api",
    //defaultApiBaseUrl: "http://localhost:3001/api",
    setJsonData: function(propertyName, value) {
        localStorage.setItem(propertyName, JSON.stringify(value));
    },
    getJsonData: function(propertyName) {
        if(localStorage.getItem(propertyName) && localStorage.getItem(propertyName) !== 'undefined'){
            return JSON.parse(localStorage.getItem(propertyName));
        }else{
            return null;
        }
    },
    getRwAccounts: async function(auth, http){
        //note: ideally this would be move to a UIHelper of some sort
        let getAccounts = async (auth, http) => {
            const accessToken = await auth.getTokenSilently();

            try {
                const { data } = await http.get(`${this.defaultApiBaseUrl}/accounts`, {
                    headers: { Authorization: `Bearer ${accessToken}` }
                });

                return data;
            } catch (e) {
                console.log(`Error: the server responded with '${e.response.status}: ${e.response.statusText}'`);
                return [];
            }
        }
        
        let rwAccounts = this.getJsonData("rwAccounts");
        let differenceInHours = 0;
        if (rwAccounts) {
            let difference = new Date().getTime() - rwAccounts.lastRequestedTime;
            differenceInHours = Math.floor(difference/1000/60/60);
        }
        if (!rwAccounts || differenceInHours > 1) {
            let result = await getAccounts(auth, http);
            let data = { accounts: result.accounts, lastRequestedTime: new Date().getTime() };
            this.setRwAccounts(data);

            const uniqueCategories = [...new Set(result.accounts.map(item => item.category))];
            uniqueCategories.unshift("All");
            this.setCategories(uniqueCategories);

            return data;
        }
        return rwAccounts;
    },
    setRwAccounts: function(value){
        if (!value) {
            localStorage.removeItem('rwAccounts');
        } else {
            this.setJsonData("rwAccounts", value);
        }
    },
    setChartFilters: function (value) {
        this.setJsonData("chartFilters", value);
    },
    getChartFilters: function () {
        let chartFilters = this.getJsonData("chartFilters");
        
        //legacy support
        if (chartFilters && !chartFilters['selectedCategoryOption']) chartFilters['selectedCategoryOption'] = 'All';
        if (chartFilters && !chartFilters['selectedDateRange']) chartFilters['selectedDateRange'] = 'Last 7 days';
        if (chartFilters && !chartFilters['selectedPatientOption']) chartFilters['selectedPatientOption'] = 'active';
        if (chartFilters && !chartFilters['selectedPatientInterval']) chartFilters['selectedPatientInterval'] = '12mo';

        if (chartFilters && chartFilters.pickerDates && chartFilters.pickerDates.startDate && chartFilters.pickerDates.endDate) {
            chartFilters.pickerDates.startDate = new Date(chartFilters.pickerDates.startDate);
            chartFilters.pickerDates.endDate = new Date(chartFilters.pickerDates.endDate);
            return chartFilters;
        } else {
            //set defaults
            chartFilters = {};
            let today = new Date();
            chartFilters['pickerDates'] = {
                startDate: new Date(today - 30 * 24 * 60 * 60 * 1000),
                endDate: today,
            };
            chartFilters['selectedIntervalFrequency'] = '1',
            chartFilters['selectedIntervalOption'] = 'd';
            chartFilters['selectedPatientOption'] = 'active';
            chartFilters['selectedPatientInterval'] = '12mo';
            chartFilters['selectedAccountsToFilter'] = [];
            chartFilters['selectedDateRange'] = 'Last 7 days';
        }

        this.setJsonData("chartFilters", chartFilters);

        return this.getJsonData("chartFilters");
    },
    setCategories: function (value) {
        this.setJsonData("categories", value);
    },
    getCategories: function () {
        return this.getJsonData("categories");
    }
}