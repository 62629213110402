import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Patients from "../views/Patients.vue";
import { authGuard } from "../auth";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        //note: im leaving below commented so that we can see what a route looks like if we want to ensure they must be authenticated before accessing the route
        {
            path: "/profile",
            name: "profile",
            component: Profile,
            beforeEnter: authGuard
        },
        {
            path: "/patients",
            name: "patients",
            component: Patients,
            beforeEnter: authGuard
        }
    ]
});

export default router;
